import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import classes from "./AboutUs.module.scss"
import AOS from "aos"
import "aos/dist/aos.css"

import Backdrop from "../Navbar/Backdrop/Backdrop"

const createEmployees = employees => {
  return employees.map((value, id) => (
    <div className={classes.employee} key={id} data-aos="fade-up">
      <p className={classes.name}>{value["Imie_nazwisko"]}</p>
      <p className={classes.position}>{value["stanowisko"]}</p>
    </div>
  ))
}

const createPhotos = (photos, setExpanded) => {
  const result = []
  for (const key in photos)
    result.push(
      <div
        data-aos="fade-up"
        key={key}
        onClick={() =>
          setExpanded({
            isExpanded: true,
            fluid: photos[key].node.childImageSharp.fluid,
          })
        }
      >
        <Img
          className={classes.photo}
          fluid={photos[key].node.childImageSharp.fluid}
        />
      </div>
    )
  return result
}

const AboutUs = ({ photos, teamPhoto, strapiData }) => {
  useEffect(() => {
    AOS.init({
      duration: 500,
    })
  }, [])

  const [expanded, setExpanded] = useState({ isExpanded: false, fluid: null })

  const expandedPhoto = expanded.isExpanded ? (
    <div className={classes.expandedPhoto}>
      <Img fluid={expanded.fluid} />
    </div>
  ) : null

  return (
    <div className={classes.container}>
      {expandedPhoto}
      <Backdrop
        show={expanded.isExpanded}
        close={setExpanded}
        params={{ isExpanded: false, fluid: null }}
      />
      <Img
        className={classes.teamPhoto}
        fluid={teamPhoto.childImageSharp.fluid}
      />
      <h1 className={classes.sectionName} data-aos="fade-left">
        O NAS
      </h1>
      <div className={classes.text} data-aos="fade-up">
        <pre className={classes.description}>{strapiData.opis_zespolu}</pre>
      </div>
      <h2 className={classes.sectionName} data-aos="fade-left">
        ZESPÓŁ
      </h2>
      <div className={classes.employees}>
        {createEmployees(strapiData.pracownicy.pracownik)}
      </div>
      <h2 className={classes.sectionName} data-aos="fade-left">
        GALERIA
      </h2>
      <div className={classes.gallery}>
        {createPhotos(photos.edges, setExpanded)}
      </div>
    </div>
  )
}

export default AboutUs
