import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import AboutUs from "../components/AboutUs/AboutUs"
import SEO from "../components/seo"

const AboutUsPage = ({ data }) => (
  <Layout>
    <SEO title="O nas" keywords="Fizjoterapia Legnica,
     Fizjoterapeuta Legnica, Fizjoterapeutka Legnica, Masaż Legnica, Masażysta Legnica,
      Masażystka Legnica, Hydroterapia Legnica, Kinezyterapia Legnica,
       lekarz Legnica, terapeuta Legnica, ból pleców Legnica, masaże Legnica,
        rehabilitacja Legnica,
        Anna Mysyk Legnica, Anna Mysyk Legnica Rehabilitacja," />
    <AboutUs photos={data.oNas} teamPhoto={data.team} strapiData={data.strapiONas}/>
  </Layout>
)

export default AboutUsPage

export const aboutUsQuery = graphql`
  query AboutUsQuery {
    oNas: allFile(filter: { relativeDirectory: { eq: "oNas" } }) {

      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 1500, maxHeight: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    team: file(relativePath: { eq: "team-croped-v2.jpg" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    strapiONas {
      pracownicy {
        pracownik {
          Imie_nazwisko
          stanowisko
        }
      }
      opis_zespolu
    }
  }
`
